import React, { useEffect, useState, useRef, useContext } from "react";
import "./Questions.css";
import {
  postGetStoryAnswer,
  postGenerateBook,
  postGetOptimizedTranscript,
  getSession,
  upSertSession,
  postGetPaymentForm,
  postCheckCouponForPayment,
} from "../../../../../core/api/Api";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Question from "./question/Question";
import {
  countValidTranscripts,
  getInputSpeechId,
  countTotalQuestions,
  checkIfChapterHaveLessThanOneQuestion,
  showHideModal,
  updateElementClassById,
  showToast,
  getDateInTimeZone,
} from "../../../../../core/common-service/CommonService";
import Modal from "../../../../common/modal/Modal";
import bg from "../../../../../assets/img/bg/aai-feature-bg.jpeg";
import Loading from "../../../../common/loading/Loading";
import PlanCard from "../../../../common/plan-card/PlanCard";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { StoreContext } from "../../../../../core/context/StoreContext";
import LockedBookModal from "../../../../_components/Modal";
import useIsMobile from "../../../../../core/hooks/useMobile";
import { svgs } from "../../../../../core/constants/svgs";

const Questions = () => {
  const [isPayModalOpen, setisPayModalOpen] = useState(false);
  const isMobile = useIsMobile();
  const { isLoggedIn, user } = useContext(StoreContext);
  let { service_session_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const modal_add_chapter_max_reached_id = "modal_add_chapter_max_reached_id";
  const modal_choose_plan_id = "modal_choose_plan_id";
  const modal_confirm_answers_id = "modal_confirm_answers_id";
  const modal_upsert_chapter_id = "modal_upsert_chapter_id";
  const modal_delete_chapter_id = "modal_delete_chapter_id";
  const modal_delete_question_id = "modal_delete_question_id";
  const modal_upsert_question_id = "modal_upsert_question_id";
  const modal_submit_chapter_changes_id = "modal_submit_chapter_changes_id";
  const [checkpoint_data, setCheckpointData] = useState({});
  const [data, setData] = useState([]);
  const [lookups, setLookups] = useState({});
  const [survey_questions, setSurveyQuestions] = useState([]);
  const [services_with_plans, setServicesWithPlans] = useState([]);
  const [service_id, setServiceId] = useState(null);
  const [general_category_id, setGeneralCategoryId] = useState(null);
  const [category_based_type_id, setCategoryBasedTypeId] = useState(null);
  const [category_length_type_id, setCategoryLengthTypeId] = useState(null);
  const [maxNumChapters, setMaxNumChapters] = useState(null);
  const [couponCodeForPayment, setCouponCodeForPayment] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isPayed, setIsPayed] = useState(false);
  const [code, setCode] = useState(user?.coupon_code ?? "");
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const [activeCollapse, setActiveCollapse] = useState("flush-collapse-");
  const [chapterToUpsert, setChapterToUpsert] = useState({});
  const [questionToUpsert, setQuestionToUpsert] = useState({});
  const chapterToUpsertResource = useRef({
    type: null,
    index: null,
    item: null,
  });
  const questionToUpsertResource = useRef({
    type: null,
    question_category_index: null,
    question_index: null,
  });
  const uploadedInputIdForChapter = useRef("");
  const uploadedImageForChapterFormData = useRef(new FormData());
  const dragItemChapter = useRef();
  const dragOverItemChapter = useRef();
  const dragItemQuestion = useRef();
  const dragOverItemQuestion = useRef();
  const mounted = useRef(true);
  const updateSessionTimeoutRef = useRef(null);

  const { chapter_to_add, index_chapter_to_add } = location.state || {};

  const addExtraChapterPlan = [
    {
      id: 1,
      service_id: null,
      category_length_type_id: null,
      price: 4.99,
      currency_id: 1,
      is_gift_card: false,
      is_active: true,
      name: t("extra_chapter"),
      description: null,
      payed_per: null,
      price_description: t("for_the_chapter"),
      plan_options: [
        {
          id: 10,
          plan_id: 3,
          is_active: true,
          name: t("add_chapter_plans.reached_the_maximum_number"),
          description: null,
        },
        {
          id: 11,
          plan_id: 3,
          is_active: true,
          name: t("add_chapter_plans.cost_every_additional_chapter"),
          description: null,
        },
      ],
      currency: {
        id: 1,
        symbol: "EUR",
        is_active: true,
        name: "Euro",
        description: null,
      },
    },
  ];

  const uploadImagesArr = [...Array(2).keys()];
  const coverPhotoInputId = "cover_media_photo";
  const coverPhotoImageId = service_session_id + "-image-cover-photo";

  const otherPhotoInputId = (id, image_index) =>
    "chap_" + id + "_media_" + image_index;
  const otherPhotoImageId = (index, image_index) =>
    service_session_id + "-image-" + index + "-" + image_index;

  // start drag drop chapters
  const dragStartChapter = (e, position) => {
    dragItemChapter.current = position;
  };

  const dragEnterChapter = (e, position) => {
    dragOverItemChapter.current = position;
  };

  const dropChapter = (e) => {
    const copyListItems = [...data];
    const dragItemContent = copyListItems[dragItemChapter.current];
    copyListItems.splice(dragItemChapter.current, 1);
    copyListItems.splice(dragOverItemChapter.current, 0, dragItemContent);
    dragItemChapter.current = null;
    dragOverItemChapter.current = null;
    setData(copyListItems);
  };
  // end drag drop chapters

  // start drag drop questions
  const dragStartQuestion = (e, position) => {
    dragItemQuestion.current = position;
  };

  const dragEnterQuestion = (e, position) => {
    dragOverItemQuestion.current = position;
  };

  const dropQuestion = (index) => (e) => {
    var copyListItems = [...data];
    const dragItemContent =
      copyListItems[index].questions[dragItemQuestion.current];
    copyListItems[index].questions.splice(dragItemQuestion.current, 1);
    copyListItems[index].questions.splice(
      dragOverItemQuestion.current,
      0,
      dragItemContent
    );
    dragItemQuestion.current = null;
    dragOverItemQuestion.current = null;
    setData(copyListItems);
  };
  // end drag drop questions

  const onCodeChange = (e) => {
    const val = e.target.value;
    setCode(val);
  };

  const handleAccordion = (e) => {
    let attr = e.target.attributes?.name?.nodeValue;
    let currentValue = attr?.split("-")?.at(-1);

    // clicking the title
    if (currentValue === undefined) {
      const current = e.target;
      const parent = current.parentElement;
      attr = parent.attributes.name.nodeValue;
      currentValue = attr?.split("-")?.at(-1);
      console.log("currentValue", currentValue);
    }

    // unlocked
    if (parseInt(currentValue) === 0 || isPayed) {
      setActiveCollapse(activeCollapse === attr ? false : attr);
      setisPayModalOpen(false);
      return;
    }

    // locked
    setActiveCollapse(activeCollapse === attr ? false : attr);

    setisPayModalOpen(activeCollapse !== attr);
  };

  const onEditAddDeleteChapter = (type, index) => (e) => {
    e.stopPropagation();
    if (type === "delete") {
      const tmp_data = [...data];
      tmp_data.splice(index, 1);
      setData(tmp_data);
      uploadImagesArr.forEach((element, image_index) => {
        const id = "chap_" + data[index].id + "_media_" + image_index;
        uploadedImageForChapterFormData.current.delete(id);
      });
      return;
    }

    chapterToUpsertResource.current = { type, index };
    showHideModal(modal_upsert_chapter_id);

    switch (type) {
      case "edit":
        setChapterToUpsert(data[index]);
        break;
      case "add":
        const tmp_data = [...data];
        const nextId = Math.max(...tmp_data.map((item) => item.id)) + 1;
        setChapterToUpsert({
          id: nextId,
          general_category_id,
          name: "",
          questions: [],
        });
        break;
      default:
        break;
    }
  };

  const onChapterToUpsertChange = (type, chap_question_index) => (e) => {
    e.preventDefault();
    const value = e.target.value;
    var tmp_chapterToUpsert = { ...chapterToUpsert };
    switch (type) {
      case "chapter-change-text":
        tmp_chapterToUpsert.name = value;
        break;
      case "question-change-text":
        tmp_chapterToUpsert.questions[chap_question_index].name = value;
        break;
      case "question-delete-text":
        if (tmp_chapterToUpsert?.questions?.length > 1) {
          tmp_chapterToUpsert?.questions.splice(chap_question_index, 1);
        }
        break;
      case "question-add-text":
        tmp_chapterToUpsert.questions.push({
          name: "",
          question_category_id: tmp_chapterToUpsert?.id,
          category_based_type_id,
          category_length_type_id,
        });
        break;
      default:
        break;
    }
    setChapterToUpsert(tmp_chapterToUpsert);
  };

  const onSubmitChapterToUpsert = () => {
    if (chapterToUpsert?.name?.length > 0) {
      if (chapterToUpsert?.questions?.length > 0) {
        showHideModal(modal_upsert_chapter_id);
        const tmp_data = [...data];
        if (chapterToUpsertResource.current.type === "edit") {
          tmp_data[chapterToUpsertResource.current.index] = chapterToUpsert;
        } else if (chapterToUpsertResource.current.type === "add") {
          // add new chapter after the selected chapter
          if (data.length < maxNumChapters) {
            tmp_data.splice(
              chapterToUpsertResource.current.index + 1,
              0,
              chapterToUpsert
            );
          } else {
            showHideModal(modal_add_chapter_max_reached_id);
            // const obj = {
            //   service_session_id,
            //   is_add_chapter: true,
            // };
            // navigate("/payment", { state: { ...obj } });
            // showToast({
            //   type: "error",
            //   message: t("you_reached_max_chapters_to_add"),
            // });
          }
          // tmp_data.push(chapterToUpsert);
        }
        if (!checkIfChapterHaveLessThanOneQuestion(tmp_data)) {
          const category_length_type_item = lookups.category_length_types.find(
            (el) => el.id === category_length_type_id
          );
          // TODO: reanble this condition
          //! is_short is removed
          // if (
          //   (category_length_type_item?.is_short &&
          //     countTotalQuestions(tmp_data) <= 30) ||
          //   (!category_length_type_item?.is_short &&
          //     countTotalQuestions(tmp_data) > 30)
          // ) {
          setData(tmp_data);
          // } else {
          //   alert("Number of questions is wrong");
          // }
        }
      } else {
        showToast({
          type: "error",
          message: t("please_add_questions_in_the_chapter"),
        });
      }
    } else {
      showToast({ type: "error", message: t("please_add_chapter_title") });
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // const base64String = reader.result.split(",")[1];
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  function base64ToBlob(base64) {
    const byteChars = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: base64.split(",")[0] });
  }

  const onUploadImageClick = (input_id) => (e) => {
    uploadedInputIdForChapter.current = input_id;
    document.getElementById(input_id).click();
  };

  const onUploadImageChange = (index, image_index) => async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    const maxSize = 300 * 1024;
    if (file.size > maxSize) {
      showToast({
        type: "error",
        message: t("image_is_too_big"),
      });
      return;
    }

    uploadedImageForChapterFormData.current.append(
      uploadedInputIdForChapter.current,
      file
    );
    const image_id = otherPhotoImageId(index, image_index);
    const base64String = await blobToBase64(file);
    localStorage.setItem(image_id, base64String);

    var reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById(image_id).setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(file);
    document
      .getElementById("container-" + uploadedInputIdForChapter.current)
      .classList.remove("d-none");
  };

  const onRemoveImage = (input_id, image_id) => (e) => {
    e.stopPropagation();
    localStorage.removeItem(image_id);
    uploadedImageForChapterFormData.current.delete(input_id);
    document.getElementById(image_id).src = "https://placehold.co/150x150";
    document.getElementById("container-" + input_id).classList.add("d-none");
  };

  function splitStringByLineBreaks(inputString) {
    const regex = /\r?\n/g; // Matches \n or \r\n
    const matches = [...inputString.matchAll(regex)];
    const result = [];

    let lastIndex = 0;

    // Iterate over the matches to split the string
    matches.forEach((match) => {
        // Extract the substring from the last index to the current match index
        const textSegment = inputString.slice(lastIndex, match.index).trim(); // Trim whitespace

        // Only add non-empty segments as objects
        if (textSegment) {
            result.push({ text: textSegment });
        }

        // Update the last index to the end of the current match
        lastIndex = match.index + match[0].length;
    });

    // Add the remaining part of the string after the last line break
    const remainingText = inputString.slice(lastIndex).trim(); // Trim whitespace
    if (remainingText) {
        result.push({ text: remainingText });
    }

    return result;
}

  const onChangeTextArea = (index) => (e) => {
    var tmp_data = [...data];
    tmp_data[index].generated_content = e.target.value;

    const arrayOfObjects = splitStringByLineBreaks(tmp_data[index].generated_content)

    tmp_data[index].generated_content_json = JSON.stringify(arrayOfObjects)

    console.log('tmp_data[index].generated_content_json', tmp_data[index].generated_content_json)

    setData(tmp_data);
  };

  const onClickWriteStory = () => {
    // uploadedImageForChapterFormData.current.append(
    //   "data",
    //   JSON.stringify(data)
    // );
    uploadedImageForChapterFormData.current.append(
      "service_session_id",
      service_session_id
    );
    setLoading(true);
    postGenerateBook(uploadedImageForChapterFormData.current)
      .then((res) => {
        if (mounted.current && res?.data?.success) {
          setLoading(false);
          const response = res.data?.success;
          saveAs(response?.url, response?.name);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onCancelWriteStory = () => {
    showHideModal(modal_confirm_answers_id);
  };

  const onClickOpenDeleteChapterModal = (index) => (e) => {
    e.stopPropagation();
    showHideModal(modal_delete_chapter_id);
    chapterToUpsertResource.current = { type: "delete", index };
  };

  const onClickDeleteChapter = (e) => {
    onEditAddDeleteChapter("delete", chapterToUpsertResource.current.index)(e);
    showHideModal(modal_delete_chapter_id);
    chapterToUpsertResource.current = { type: null, index: null };
  };

  const onEditDeleteQuestion =
    (question_category_index, question_index) => (type) => (e) => {
      e.preventDefault();
      questionToUpsertResource.current = {
        type,
        question_category_index,
        question_index,
      };
      switch (type) {
        case "edit":
          setQuestionToUpsert(
            data[question_category_index].questions[question_index]
          );
          showHideModal(modal_upsert_question_id);
          break;
        case "delete":
          showHideModal(modal_delete_question_id);
          break;
        case "optimize":
          const question_transcript =
            data?.[question_category_index]?.questions[question_index]
              ?.transcript ?? null;
          if (question_transcript?.length > 0) {
            setLoading(true);
            const optimize_icon_id =
              getInputSpeechId(
                data[question_category_index].questions[question_index]
              ) + "_optimize_icon";
            updateElementClassById(optimize_icon_id, "fa-fade", "add");
            postGetOptimizedTranscript({ transcript: question_transcript })
              .then((res) => {
                setLoading(false);
                updateElementClassById(optimize_icon_id, "fa-fade", "remove");
                const response = res?.data?.success ?? null;
                if (response) {
                  const tmp_data = [...data];
                  tmp_data[question_category_index].questions[
                    question_index
                  ].transcript = response?.content;
                  setData(tmp_data);

                  const question =
                    data?.[question_category_index]?.questions[question_index];
                  document.getElementById(getInputSpeechId(question)).value =
                    response?.content;
                }
              })
              .catch((e) => {
                setLoading(false);
                updateElementClassById(optimize_icon_id, "fa-fade", "remove");
              });
          }
          break;
        default:
          break;
      }
    };

  const onClickDeleteQuestion = (e) => {
    e.preventDefault();
    const tmp_data = [...data];
    if (
      tmp_data[questionToUpsertResource.current.question_category_index]
        .questions.length > 1
    ) {
      tmp_data[
        questionToUpsertResource.current.question_category_index
      ].questions.splice(questionToUpsertResource.current.question_index, 1);
      setData(tmp_data);
    } else {
      showToast({
        type: "warning",
        message: t("at_lest_one_question_is_required"),
      });
    }

    showHideModal(modal_delete_question_id);
    questionToUpsertResource.current = {
      type: null,
      question_category_index: null,
      question_index: null,
    };
  };

  const onQuestionToUpsertChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setQuestionToUpsert((prevState) => ({
      ...prevState,
      name: value,
    }));
  };

  const onClickEditAddQuestion = (e) => {
    e.preventDefault();
    const objResource = questionToUpsertResource.current;
    var tmp_data = [...data];
    switch (objResource.type) {
      case "edit":
        tmp_data[objResource.question_category_index].questions[
          objResource.question_index
        ].name = questionToUpsert.name;
        setData(tmp_data);
        showHideModal(modal_upsert_question_id);
        break;

      default:
        break;
    }
  };

  const onSurveyAnswerClick = (index, answer_index) => (e) => {
    e.preventDefault();
    var tmp_survey_questions = [...survey_questions];
    tmp_survey_questions[index].survey_answers.forEach((element, i) => {
      element.is_selected = answer_index === i;
    });
    setSurveyQuestions(tmp_survey_questions);
  };

  const checkAllSurveyAnswersSelected = (data) => {
    return data.every((question) => {
      const selectedAnswer = question.survey_answers.find(
        (answer) => answer.is_selected
      );
      return selectedAnswer !== undefined;
    });
  };

  const updateSessionData = () => {
    var tmp_checkpoint_data = { ...checkpoint_data };
    var tmp_data = [...data];
    if (tmp_data.length > 0) {
      tmp_checkpoint_data.data = tmp_data;
      clearTimeout(updateSessionTimeoutRef.current);
      updateSessionTimeoutRef.current = setTimeout(() => {
        if (isLoggedIn) {
          upSertSession({
            service_session_id,
            checkpoint_data: tmp_checkpoint_data,
          })
            .then((res) => {})
            .catch((e) => {});
        } else {
          let trial_session = JSON.parse(localStorage.getItem("trial_session"));
          trial_session.checkpoint_data = tmp_checkpoint_data;
          localStorage.setItem("trial_session", JSON.stringify(trial_session));
        }
      }, 100);
    }
  };

  const updateCircularProgressBar = (d) => {
    const valid_transcript_count = countValidTranscripts(d);
    const total_questions_count = countTotalQuestions(d);
    if (total_questions_count > 0) {
      setProgressBarPercentage(
        (valid_transcript_count / total_questions_count) * 100
      );
    }
  };

  const loadCachedImages = async (data) => {
    if (coverPhotoImageId) {
      const inputEl = document.getElementById(coverPhotoImageId);
      if (inputEl) {
        const base64String = await localStorage.getItem(coverPhotoImageId);
        if (base64String) {
          document.getElementById(coverPhotoImageId).src = base64String;
          const file = await base64ToBlob(base64String);
          uploadedImageForChapterFormData.current.append(
            coverPhotoInputId,
            file
          );
          document
            .getElementById("container-" + coverPhotoInputId)
            .classList.remove("d-none");
        }
      }
    }

    data.forEach(async (d, i) => {
      await uploadImagesArr.forEach(async (el_image, image_index) => {
        const image_id = otherPhotoImageId(i, image_index);
        if (image_id) {
          const inputEl = document.getElementById(image_id);
          if (inputEl) {
            const base64String = await localStorage.getItem(image_id);
            if (base64String) {
              document.getElementById(image_id).src = base64String;
              const file = await base64ToBlob(base64String);
              uploadedImageForChapterFormData.current.append(
                "chap_" + d?.id + "_media_" + image_index,
                file
              );
              document
                .getElementById(
                  "container-chap_" + d?.id + "_media_" + image_index
                )
                .classList.remove("d-none");
            }
          }
        }
      });
    });
  };

  // related to .then in getData function
  const onGetDataSuccess = (response) => {
    if (mounted.current && response) {
      var data = { ...response?.checkpoint_data };
      // check if there is id or not
      let nextId = Math.max(
        ...data.data.map((item) =>
          item.id !== undefined ? item.id : -Infinity
        )
      );
      data.data.forEach((item) => {
        if (!item?.id) {
          item.id = ++nextId;
        }
      });

      setCheckpointData(data ?? {});
      if (chapter_to_add) {
        data.data.splice(index_chapter_to_add + 1, 0, chapter_to_add);
        data.data.push(chapter_to_add);
      }
      setData(data?.data);
      if (data?.data?.length > 0) {
        setActiveCollapse("flush-collapse-0");
      }
      setLookups(data?.lookups);
      setSurveyQuestions(data?.lookups?.survey_questions);
      setServiceId(data?.service_id);
      setGeneralCategoryId(data?.general_category_id);
      setCategoryBasedTypeId(data?.category_based_type_id);
      setCategoryLengthTypeId(data?.category_length_type_id);
      setMaxNumChapters(response?.max_num_chapters);
      setIsPayed(response?.is_payed);

      setServicesWithPlans(data?.services_with_plans);

      updateCircularProgressBar(data?.data);
    }
  };

  const getData = () => {
    setLoading(true);
    if (isLoggedIn) {
      getSession(service_session_id)
        .then((res) => {
          setLoading(false);
          const response = res?.data?.success;
          onGetDataSuccess(response);
        })
        .catch((e) => {
          if (mounted.current) setLoading(false);
        });
    } else {
      setLoading(false);
      try {
        if (localStorage.getItem("trial_session")) {
          let response = JSON.parse(localStorage.getItem("trial_session"));
          onGetDataSuccess(response);
        }
      } catch (error) {
        showToast({ type: "error", message: "Error in trial session" });
      }
    }

    window.scrollTo(0, 0);
  };

  const onresult = (index, q_index) => (transcript_str) => {
    let tmp_data = [...data];
    tmp_data[index].questions[q_index].transcript = transcript_str;
    setData(tmp_data);
    updateCircularProgressBar(tmp_data);
  };

  const showPlansModel = () => {
    if (!isLoggedIn) {
      navigate("/register");
      return;
    }

    console.log("service_session_id", service_session_id);
    // console.log('plan_id', plan_id)
    navigate("/payment");
  };

  const onChooseAddChapterPlanClick = () => {
    const obj = {
      service_session_id,
      is_add_chapter: true,
      chapter_to_add: chapterToUpsert,
      index_chapter_to_add: chapterToUpsertResource.current.index,
    };
    navigate("/payment", { state: { ...obj } });
  };

  const onSubmitChapterClick = (item, index) => (e) => {
    e.preventDefault();
    chapterToUpsertResource.current = { item, index };
    showHideModal(modal_submit_chapter_changes_id);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const index = chapterToUpsertResource.current.index;
    // const item = chapterToUpsertResource.current.item;
    if (!isPayed && index > 0) {
      showHideModal(modal_choose_plan_id);
      return;
    }
    if (checkAllSurveyAnswersSelected(survey_questions)) {
      showHideModal(modal_submit_chapter_changes_id);
      const selected_survey_questions = survey_questions.map((item) => ({
        service_session_id,
        survey_question_id: item?.id,
        survey_answer_id: item.survey_answers.find(
          (q_item) => q_item?.is_selected
        )?.id,
      }));

      setLoading(true);
      // var d = getDataNeededChatGPT(data);
      // d = d.filter((el) => el.id === item.id);
      postGetStoryAnswer({
        data: data[index],
        selected_survey_questions,
        index,
        isPayed,
      })
        .then((res) => {
          setLoading(false);
          const response = res.data?.success;
          let tmp_data = [...data];
          tmp_data[index].submitted_at = getDateInTimeZone();
          tmp_data[index].generated_content = response?.content;
          tmp_data[index].generated_content_json = JSON.stringify(
            response?.content_json
          );
          if (!isPayed) {
            tmp_data[index].generated_count =
              (tmp_data[index].generated_count ?? 0) + 1;
          }
          setData(tmp_data);

          document.getElementById(`generated_paragraph_${index}`).style.height = "auto";

          setTimeout(() => {
            document.getElementById(`generated_paragraph_${index}`).scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "start",
            });
          }, [100]);
        })
        .catch((err) => {
          setLoading(false);
        });
      chapterToUpsertResource.current = {};
    } else {
      showToast({
        type: "error",
        message: t("error.please_answer_all_questions"),
      });
    }
  };

  const getPaymentForm = () => {
    const obj = { service_session_id };
    postGetPaymentForm(obj)
      .then((res) => {
        const response = res?.data?.success;
        if (mounted.current && response) {
        } else {
        }
      })
      .catch((e) => {});
  };

  const onChoosePlanClick = (item) => (e) => {
    const plan_id = item?.plans[0]?.id
    const obj = {
      service_session_id,
      plan_id: plan_id,
    };
    navigate("/payment", { state: { ...obj } });
    return;
  };

  const checkCouponForPayment = () => {
    postCheckCouponForPayment({ service_session_id, code })
      .then((res) => {
        if (mounted.current && res?.data?.success) {
          setLoading(false);
          const response = res.data?.success;
          setIsPayed(response?.is_payed);
          if (response?.is_payed) {
            showHideModal(modal_choose_plan_id);
          } else {
            setCouponCodeForPayment(response?.coupon);
          }
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    mounted.current = true;
    updateSessionData();
    loadCachedImages(data);
    return () => {
      mounted.current = false;
    };
  }, [data]);

  useEffect(() => {
    const accrodionBtn = document.getElementsByClassName("accordion-button");
    for (let i = 0; i < accrodionBtn.length; i++) {
      if (accrodionBtn[i].attributes.name.nodeValue === activeCollapse) {
        document
          .getElementById(activeCollapse)
          ?.classList.add("accordion-collapse-show");
        document
          .getElementsByName(activeCollapse)[0]
          ?.classList.add("accordion-button-show");
      } else {
        document
          .getElementById(accrodionBtn[i].attributes.name.nodeValue)
          ?.classList.remove("accordion-collapse-show");
        document
          .getElementsByName(accrodionBtn[i].attributes.name.nodeValue)[0]
          ?.classList.remove("accordion-button-show");
      }
    }
  }, [activeCollapse]);

  useEffect(() => {
    mounted.current = true;
    getData();
    // added this in case of free trial
    if (isLoggedIn) {
      getPaymentForm();
    }

    window.scrollTo(0, 0);
    return () => {
      mounted.current = false;
    };
  }, []);

  if (data?.length === 0) {
    return <Loading />;
  }

  return (
    <section
      className="aai-features py-100"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div style={{ position: "fixed", bottom: 10, right: 10, zIndex: 10 }}>
        <div style={{ width: 60, height: 60, color: "#6bdba7" }}>
          <CircularProgressbar
            value={progressBarPercentage}
            text={`${parseInt(progressBarPercentage)}%`}
            styles={buildStyles({
              textColor: "#ff4000",
              pathColor: "#ff4000",
            })}
          />
        </div>
      </div>
      {/* {services_with_plans?.length > 0 && (
        <Modal id={modal_choose_plan_id}>
          <div className="container">
            {!isPayed && (
              <div className="d-flex align-items-center mb-3">
                <div className="aai-form-container me-3">
                  <input
                    className="form-control shadow-none"
                    id="coupon"
                    name="coupon"
                    type="text"
                    placeholder={t("enter_your_coupon")}
                    value={code}
                    onChange={onCodeChange}
                  />
                </div>
                <button
                  onClick={checkCouponForPayment}
                  type="submit"
                  // disabled={loading}
                  className="aai-btn btn-pill-solid d-flex justify-content-center"
                >
                  <i className="fa-solid fa-check"></i>
                </button>
              </div>
              {couponCodeForPayment?.discount_percentage && (
                <span className="me-3">
                  {couponCodeForPayment?.discount_percentage}%
                </span>
              )}
              <button
                onClick={checkCouponForPayment}
                type="submit"
                // disabled={loading}
                className="aai-btn btn-pill-solid d-flex justify-content-center"
              >
                <i className="fa-solid fa-check"></i>
              </button>
            </div>
          )}
          {services_with_plans?.length > 0 &&
            services_with_plans.map((item, index) => (
              <PlanCard
                key={index}
                item={item}
                onButtonClick={onChoosePlanClick(item)}
              />
            ))}
          </div>
        </Modal>
      )} */}
      <Modal id={modal_add_chapter_max_reached_id}>
        <div className="container">
          {addExtraChapterPlan?.length > 0 &&
            addExtraChapterPlan.map((item, index) => (
              <PlanCard
                key={index}
                item={item}
                onButtonClick={onChooseAddChapterPlanClick}
              />
            ))}
        </div>
      </Modal>
      <Modal id={modal_confirm_answers_id}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="aai-section-title mb-3 text-center mb-5">
                <h2 className="section-title">Warning</h2>
                <p className="section-desc">
                  You can generate once, so make sure of the texts set!
                </p>
                <div className="mt-4">
                  <button className="aai-gradient-outline-btn me-4">
                    Generate
                  </button>
                  <button
                    className="aai-btn btn-pill-solid"
                    onClick={onCancelWriteStory}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal id={modal_upsert_chapter_id}>
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="aai-form-container">
                <h4 className="aai-testimonial-author-name mb-2">
                  {t("chapter_title")}
                </h4>
                <input
                  className="form-control shadow-none"
                  type="text"
                  placeholder={t("add_your_chapter_title")}
                  value={chapterToUpsert?.name ?? ""}
                  onChange={onChapterToUpsertChange(
                    "chapter-change-text",
                    null
                  )}
                />
              </div>
              <div className="aai-form-container">
                <div className="d-flex align-items-center  mt-4 mb-2">
                  <h4 className="aai-testimonial-author-name">
                    {t("questions")}
                  </h4>
                  <button
                    className="aai-breadcrumb-link text-decoration-underline ms-2"
                    onClick={onChapterToUpsertChange("question-add-text", null)}
                    style={{ fontSize: "16px" }}
                  >
                    {t("add")} <span style={{ fontSize: "1.2rem" }}>+</span>
                  </button>
                </div>
                {chapterToUpsert?.questions?.map(
                  (chap_question_item, chap_question_index) => (
                    <div
                      key={"chapter-upsert-" + chap_question_index}
                      className="d-flex align-items-center mb-2"
                    >
                      <input
                        className="form-control shadow-none "
                        type="text"
                        placeholder={t("add_your_question_title")}
                        value={chap_question_item?.name ?? ""}
                        onChange={onChapterToUpsertChange(
                          "question-change-text",
                          chap_question_index
                        )}
                      />
                      <div className="dropdown">
                        <button
                          style={{ marginLeft: "1rem" }}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa-solid fa-trash icons"
                            style={{ color: "#FF2A09" }}
                          />
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            onClick={onChapterToUpsertChange(
                              "question-delete-text",
                              chap_question_index
                            )}
                          >
                            <span className="dropdown-item">{t("yes")}</span>
                          </li>
                          <li>
                            <span className="dropdown-item">{t("no")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </div>
              <button
                className="aai-btn btn-pill-solid mt-2"
                disabled={loading}
                onClick={onSubmitChapterToUpsert}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal id={modal_delete_chapter_id}>
        <div className="container">
          <div>
            <h2 className="section-title mb-4 text-center">
              {t("are_you_sure_you_want_to_delete_this_chapter")}?
            </h2>
            <div className="d-flex justify-content-center">
              <button
                className="aai-btn btn-pill-solid me-4"
                onClick={() => showHideModal(modal_delete_chapter_id)}
              >
                {t("cancel")}
              </button>
              <button
                className="aai-gradient-outline-btn"
                onClick={onClickDeleteChapter}
              >
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal id={modal_delete_question_id}>
        <div className="container">
          <div>
            <h2 className="section-title mb-4 text-center">
              {t("are_you_sure_you_want_to_delete_this_question")}?
            </h2>
            <div className="d-flex justify-content-center">
              <button
                className="aai-btn btn-pill-solid me-4"
                onClick={() => showHideModal(modal_delete_question_id)}
              >
                {t("cancel")}
              </button>
              <button
                className="aai-gradient-outline-btn"
                onClick={onClickDeleteQuestion}
              >
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal id={modal_upsert_question_id}>
        <div className="container">
          <div>
            <h2 className="section-title mb-4 text-center">
              {t("edit_this_question")}
            </h2>
            <input
              className="form-control shadow-none "
              type="text"
              placeholder="Enter your question"
              value={questionToUpsert?.name ?? ""}
              onChange={onQuestionToUpsertChange}
            />
            <div className="d-flex justify-content-center mt-4">
              <button
                className="aai-btn btn-pill-solid me-4"
                onClick={() => showHideModal(modal_upsert_question_id)}
              >
                {t("cancel")}
              </button>
              <button
                className="aai-gradient-outline-btn"
                onClick={onClickEditAddQuestion}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal id={modal_submit_chapter_changes_id}>
        <div className="container">
          <div>
            <h2 className="section-title mb-4 text-center">
              {t("check_spelling_before_generating_chapter")}
            </h2>
            <div>
              {survey_questions.map((item, index) => (
                <div key={"survey_question_" + index} className="mb-3">
                  <h4 className="writing-step-title">
                    {index + 1 + "- " + item?.name}
                  </h4>
                  {item.survey_answers.map((answer_item, answer_index) => (
                    <p
                      key={"survey_answer_" + answer_index}
                      className="aai-price-list-item-text cursor-pointer"
                      onClick={onSurveyAnswerClick(index, answer_index)}
                    >
                      <i
                        className={
                          "fa-regular fa-circle" +
                          (answer_item?.is_selected ? "-check" : "")
                        }
                      ></i>{" "}
                      {answer_item?.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="aai-btn btn-pill-solid me-4"
                onClick={() => showHideModal(modal_submit_chapter_changes_id)}
              >
                {t("review")}
              </button>
              <button className="aai-gradient-outline-btn" onClick={onSubmit}>
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="aai-section-title mb-3 text-center mb-5">
              <h2 className="section-title">
                {t("lets_build_your_story_together")}
              </h2>
              <p className="section-desc">
                {t("here_are_the_questions_of_our_virtual_writer")}
              </p>
            </div>
          </div>
        </div>

        <li
          className="work-list-item d-flex align-items-center me-3"
          style={{
            justifyContent: isMobile ? "center" : "",
            width: "fit-content",
            marginBottom: "1rem",
          }}
          onClick={onUploadImageClick(coverPhotoInputId)}
        >
          <input
            type="file"
            multiple={false}
            accept="image/jpeg, image/png, image/jpg"
            id={coverPhotoInputId}
            className="d-none"
            onChange={onUploadImageChange("cover", "photo")}
          />
          <div className="upload">
            <div className="cursor-pointer">
              <img src={svgs.upload} alt="upload" width={24} />
              <span className="aai-testimonial-author-title ms-2 upload_cover_photo">
                {t("upload_cover_photo")}
              </span>
            </div>
            <div
              id={"container-" + coverPhotoInputId}
              className="selected-img-container d-none"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <div
                className="img-delete-button"
                onClick={onRemoveImage(coverPhotoInputId, coverPhotoImageId)}
              >
                x
              </div>
              <img
                id={coverPhotoImageId}
                className="img-fluid"
                src="https://placehold.co/150x150"
                style={{ border: "1px dotted black" }}
                alt=""
              />
            </div>
          </div>
        </li>
        <div
          className="row justify-content-center"
          style={{ gap: !isMobile ? "1.5rem" : "1rem" }}
        >
          {data?.map((item, index) => (
            <div
              id={"container-flush-collapse-" + index}
              key={"question-" + index}
            >
              <div
                className="col-xl-12 col-lg mt-3 mt-xl-0"
                // data-aos="fade-up"
                // data-aos-delay="100"
              >
                <div className="aai-accordions">
                  <div className="accordion accordion-flush">
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        style={{
                          border: "1px solid #606060",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          paddingBottom: "5px",
                        }}
                      >
                        <div
                          name={"flush-collapse-" + index}
                          className="accordion-button flex-wrap"
                          style={{ padding: "0px" }}
                          type="button"
                          onClick={handleAccordion}
                          onDragStart={(e) =>
                            isPayed && dragStartChapter(e, index)
                          }
                          onDragEnter={(e) =>
                            isPayed && dragEnterChapter(e, index)
                          }
                          onDragEnd={isPayed ? dropChapter : undefined}
                          draggable={isPayed}
                        >
                          {!isMobile && (
                            <img
                              src={svgs.draggable}
                              alt="draggable"
                              width={24}
                            />
                          )}
                          {(!isPayed && index !== 0) && !isMobile && (
                            <i
                              className="fa-solid fa-lock"
                              style={{ marginRight: "1rem" }}
                            ></i>
                          )}
                          <div
                            className={`${
                              isMobile ? "" : "pe-4"
                            } top_question_${index}`}
                            style={{
                              cursor: isPayed ? "move" : "pointer",
                              marginRight: isMobile ? '1rem' : 'unset',
                              color: index !== 0 || isPayed ? "gray" : "",
                            }}
                          >
                            {t("chapter") + (index + 1) + ': '}{item?.name}
                          </div>
                          {(index === 0 || isPayed) && (
                            <div className="d-flex" style={{gap: isMobile ? '.5rem' : 'unset'}}>
                              {/* Edit */}
                              <button
                                className={`aai-breadcrumb-link text-decoration-underline ${
                                  isMobile ? "me-2" : "me-4"
                                }`}
                                onClick={onEditAddDeleteChapter("edit", index)}
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </button>
                              {/* Add */}
                              {isPayed && (
                                <button
                                  className={`aai-breadcrumb-link text-decoration-underline ${
                                    isMobile ? "me-2" : "me-4"
                                  }`}
                                  onClick={onEditAddDeleteChapter("add", index)}
                                >
                                  <i className="fa-solid fa-plus"></i>
                                </button>
                              )}
                              {/* Delete */}
                              <button
                                className={`aai-breadcrumb-link text-decoration-underline ${
                                  isMobile ? "me-2" : "me-4"
                                }`}
                                onClick={onClickOpenDeleteChapterModal(index)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          )}
                            {(index === 0 && !isPayed) && (<div
                              style={{
                                padding: "0 .5rem",
                                backgroundColor: "#FF4000",
                                color: "#FEEFDD",
                                borderRadius: "4px",
                              }}
                              onClick={(e) => {e.stopPropagation()}}
                            >
                              <p className="first-chapter-free">
                                {t("first_chapter_is_free")}
                              </p>
                            </div>)}
                        </div>
                      </h2>
                      <div
                        id={"flush-collapse-" + index}
                        className="accordion-collapse "
                        style={{ position: "relative" }}
                      >
                        <div
                          className="accordion-body"
                          style={{
                            padding: "0px",
                            filter: index === 0 || isPayed ? "" : "blur(3px)",
                            userSelect: index === 0 || isPayed ? "" : "none",
                            pointerEvents: index === 0 || isPayed ? "" : "none",
                          }}
                        >
                          {item?.questions?.map((question, q_index) => {
                            return (
                              <Question
                                key={"question-" + q_index}
                                onDragStart={(e) =>
                                  isPayed && dragStartQuestion(e, q_index)
                                }
                                onDragEnter={(e) =>
                                  isPayed && dragEnterQuestion(e, q_index)
                                }
                                onDragEnd={
                                  isPayed ? dropQuestion(index) : undefined
                                }
                                draggable={isPayed}
                                question={question}
                                onresult={onresult(index, q_index)}
                                onEditDeleteQuestion={onEditDeleteQuestion(
                                  index,
                                  q_index
                                )}
                              />
                            );
                          })}
                          <div className="upload-image">
                            <div className="d-flex">
                              {uploadImagesArr.map(
                                (image_item, image_index) => (
                                  <li
                                    key={
                                      "select-image-" +
                                      index +
                                      "-" +
                                      image_index
                                    }
                                    className="work-list-item d-flex align-items-center me-3"
                                    style={{
                                      width: "fit-content",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      multiple={false}
                                      accept="image/jpeg, image/png, image/jpg"
                                      id={otherPhotoInputId(
                                        item?.id,
                                        image_index
                                      )}
                                      className="d-none"
                                      onChange={onUploadImageChange(
                                        index,
                                        image_index
                                      )}
                                    />
                                    <div
                                      style={{
                                        backgroundColor: "gray",
                                        padding: "0.5rem 0.5rem",
                                        backgroundColor: "#f2c99d",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        borderRadius: "0.5rem",
                                      }}
                                    >
                                      <div
                                        className="cursor-pointer"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={svgs.upload}
                                          alt="upload"
                                          width={24}
                                        />
                                        <div
                                          onClick={onUploadImageClick(
                                            "chap_" +
                                              item?.id +
                                              "_media_" +
                                              image_index
                                          )}
                                          className="aai-testimonial-author-title ms-2"
                                        >
                                          {t("upload_image")} {image_index + 1}
                                        </div>
                                      </div>
                                      <div
                                        id={
                                          "container-" +
                                          otherPhotoInputId(
                                            item?.id,
                                            image_index
                                          )
                                        }
                                        className="selected-img-container d-none"
                                        style={{
                                          margin: "1rem",
                                        }}
                                      >
                                        <div
                                          className="img-delete-button"
                                          onClick={onRemoveImage(
                                            otherPhotoInputId(
                                              item?.id,
                                              image_index
                                            ),
                                            otherPhotoImageId(
                                              index,
                                              image_index
                                            )
                                          )}
                                        >
                                          x
                                        </div>
                                        <img
                                          id={otherPhotoImageId(
                                            index,
                                            image_index
                                          )}
                                          className="img-fluid"
                                          style={{ border: "1px dotted black" }}
                                          src="https://placehold.co/150x150"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </div>
                            <div
                              className="d-flex mt-5"
                              style={{
                                marginBottom: isMobile ? "3rem" : "5rem",
                                gap: isMobile ? "1rem" : "unset",
                                flexDirection: isMobile ? "column" : "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <button
                                  className="aai-btn btn-pill-solid btns"
                                  disabled={loading}
                                  onClick={onSubmitChapterClick(item, index)}
                                >
                                  {loading ? (
                                    <span>
                                      {t("loading")}{" "}
                                      <i className="fa-solid fa-spinner fa-spin"></i>
                                    </span>
                                  ) : (
                                    t("submit_chapter")
                                  )}
                                </button>
                              </div>
                              <div
                                className="d-flex"
                                style={{ marginLeft: "1rem" }}
                              >
                                {index > 0 && (
                                  <button
                                    className="aai-btn btn-pill-solid me-2 btns"
                                    onClick={() => {
                                      document
                                        .getElementsByName(
                                          "flush-collapse-" + (index - 1)
                                        )[0]
                                        .click();
                                    }}
                                  >
                                    {t("previous")}
                                  </button>
                                )}
                                {index < data?.length - 1 && (
                                  <button
                                    className="aai-btn btn-pill-solid btns"
                                    onClick={() =>
                                      document
                                        .getElementsByName(
                                          "flush-collapse-" + (index + 1)
                                        )[0]
                                        .click()
                                    }
                                  >
                                    {t("next")}
                                  </button>
                                )}
                              </div>
                            </div>

                            <div
                              className="aai-comment-input mt-3"
                              id={`generated_paragraph_${index}`}
                            >
                              {item?.generated_content && (
                                <>
                                  <h2
                                    className="pe-4 top_question"
                                    style={{
                                      fontWeight: "600",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    {t("your_chapter")}
                                  </h2>
                                  <textarea
                                    style={{ minHeight: 500 }}
                                    className="form-control shadow-none"
                                    value={
                                      item?.generated_content ?? "No Response"
                                    }
                                    onChange={onChangeTextArea(index)}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "1rem" }}
        >
          <button
            className="aai-btn btn-pill-solid mt-4"
            disabled={loading}
            onClick={onClickWriteStory}
          >
            {t("retrieve_my_book")}
          </button>
        </div>
      </div>
      {services_with_plans.map((item, index) => (
        <LockedBookModal
          key={index}
          open={isPayModalOpen}
          setOpen={setisPayModalOpen}
          servicesWithPlans={services_with_plans}
          isPayed={isPayed}
          code={code}
          onCodeChange={onCodeChange}
          checkCouponForPayment={checkCouponForPayment}
          onChoosePlanClick={onChoosePlanClick(item)}
        />
      ))}
    </section>
  );
};

export default Questions;
