import React, { useRef, useEffect, useMemo, useState } from "react";
import { getGeneralCategoriesById } from "../../../core/api/Api";
import { useNavigate, useParams } from "react-router-dom";
import FeatureCard from "../../common/feature-card/FeatureCard";
import bg from "../../../assets/img/bg/aai-feature-bg.jpeg";
import img from "../../../assets/img/features/fi-1.svg";
import addUserIco from "../../..//assets/img/icons/Add-User.svg";
import homeIco from "../../../assets/img/icons/Home.svg";
import walletIco from "../../../assets/img/icons/Wallet.svg";
import Loading from "../../common/loading/Loading";
import PlanCard from "../../common/plan-card/PlanCard";
import { useTranslation } from "react-i18next";
import { svgs } from "../../../core/constants/svgs";

const GeneralCategories = () => {
  const { t, i18n } = useTranslation();
  let { service_id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [is_online_assistant, setIsOnlineAssistant] = useState(false);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);

  const goToQuestionCategoriesDetails = (item) => () => {
    if (item?.is_active) {
      navigate("/general-categories-options/" + service_id + "/" + item?.id);
    }
  };

  const getData = () => {
    setLoading(true);
    getGeneralCategoriesById(service_id)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
          if (data?.length === 1 && data?.[0]?.is_online_assistant) {
            setIsOnlineAssistant(true);
          }
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const page_title = useMemo(
    () =>
      is_online_assistant
        ? // ? "Which plan?"
          ""
        : t("what_do_you_want_to_write_about") + "?",
    [data, i18n.language]
  );

  const page_description = useMemo(
    () =>
      is_online_assistant
        ? // ? "Here you can choose the plan of the selected service"
          ""
        : t("here_you_can_choose_the_kind_of_story_to_write_about"),
    [data, i18n.language]
  );

  useEffect(() => {
    mounted.current = true;
    getData();
    return () => {
      mounted.current = false;
    };
  }, []);

  if (data?.length === 0) {
    return <Loading />;
  }

  return (
    <section
      className="aai-features py-100"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="aai-section-title mb-3 text-center mb-5">
              <h2 className="section-title">{page_title}</h2>
              <p className="section-desc">{page_description}</p>
            </div>
          </div>
        </div>
        {is_online_assistant ? (
          <>
            <div
              className="writing-steps-wrapper mb-5"
              //  data-aos="fade-up"
            >
              <div className="row">
                <div className="col-lg-3">
                  <div className="writing-step">
                    <img src={walletIco} className="writing-step-icon" alt="" />
                    <h3 className="writing-step-title">
                      {t("plan.buy.title")}
                    </h3>
                    <p className="writing-step-desc">
                      {t("plan.buy.description")}
                    </p>
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <svg
                        width="13"
                        height="20"
                        viewBox="0 0 13 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L11.4211 9.52632L1 19"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="writing-step">
                    <img
                      src={addUserIco}
                      className="writing-step-icon"
                      alt=""
                    />
                    <h3 className="writing-step-title">
                      {t("plan.get_in_touch.title")}
                    </h3>
                    <p className="writing-step-desc">
                      {t("plan.get_in_touch.description")}
                    </p>
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <svg
                        width="13"
                        height="20"
                        viewBox="0 0 13 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L11.4211 9.52632L1 19"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="writing-step">
                    <img
                      src={addUserIco}
                      className="writing-step-icon"
                      alt=""
                    />
                    <h3 className="writing-step-title">
                      {t("plan.interviews.title")}
                    </h3>
                    <p className="writing-step-desc">
                      {t("plan.interviews.description")}
                    </p>
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <svg
                        width="13"
                        height="20"
                        viewBox="0 0 13 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L11.4211 9.52632L1 19"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="writing-step">
                    <img src={homeIco} className="writing-step-icon" alt="" />
                    <h3 className="writing-step-title">
                      {t("plan.receive_your_book.title")}
                    </h3>
                    <p className="writing-step-desc">
                      {t("plan.receive_your_book.description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {data?.[0]?.service?.plans.map((item, index) => (
              <PlanCard key={index} item={item} />
            ))}
          </>
        ) : (
          <div className="row justify-content-center">
            {data?.map((item, index) => (
              <FeatureCard
                key={"service-" + index}
                onClick={goToQuestionCategoriesDetails(item)}
                img={index === 0 ? svgs.autobiography : svgs.writeAStory}
                name={item?.name}
                description={item?.description}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default GeneralCategories;
