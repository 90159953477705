import React from "react";
import Question from "./questions/question/Question";

const AnotherStoryGeneralCategoryOptions = ({ data, onresult, isVisible = true }) => {
  return (
    <div id='another_story_general_category_options' className="row justify-content-center" style={isVisible ? {height: 'auto', visibility: 'visible'} : {height: '0px', visibility: 'hidden'}}>
      {data?.general_categories?.[0]?.question_categories?.map(
        (item, index) => (
          <div
            id={"container-flush-collapse-" + index}
            key={"question-" + index}
            className="col-lg-8"
          >
            <div className="col-xl-12 mt-5 mt-xl-0">
              <div className="aai-accordions">
                <div className="accordion accordion-flush">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button name={"flush-collapse-" + index} type="button">
                        {item?.name}
                      </button>
                    </h2>
                    <div
                      id={"flush-collapse-" + index}
                      className="accordion-collapse accordion-collapse-show"
                    >
                      <div className="accordion-body">
                        {item?.questions?.map((question, q_index) => (
                          <div key={"div-another-story-question-" + q_index}>
                            {q_index === 0 && (
                              <Question
                                key={"another-story-question-" + q_index}
                                question={question}
                                onresult={onresult(index, q_index)}
                                onEditDeleteQuestion={() => {}}
                                showSideOption={false}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AnotherStoryGeneralCategoryOptions;
