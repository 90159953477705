import React, { useRef, useEffect, useState } from "react";
import { getAllServices } from "../../core/api/Api";
import { useNavigate } from "react-router-dom";
import FeatureCard from "../common/feature-card/FeatureCard";
import bg from "../../assets/img/bg/aai-feature-bg.jpeg";
import img from "../../assets/img/features/fi-1.svg";
import Loading from "../common/loading/Loading";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);

  const goToServiceDetails = (item) => () => {
    if (item?.is_active) {
      navigate("/general-categories/" + item?.id);
    }
  };

  const getData = () => {
    setLoading(true);
    getAllServices()
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  useEffect(() => {
    mounted.current = true;
    navigate("/general-categories/1");
    return;
    getData();
    return () => {
      mounted.current = false;
    };
  }, []);

  if (data?.length === 0) {
    return <Loading />;
  }

  return (
    <section
      className="aai-features py-100"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="aai-section-title mb-3 text-center mb-5">
              <h2 className="section-title">{t("choose_your_service")}</h2>
              <p className="section-desc">
                {t("here_you_can_choose_the_service_you_want_to_start_with")}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {data?.map((item, index) => (
            <FeatureCard
              key={"service-" + index}
              onClick={goToServiceDetails(item)}
              img={img}
              name={item?.name}
              description={item?.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Home;
