import React from "react";
import FeatureCard from "../../../common/feature-card/FeatureCard";
import { useTranslation } from "react-i18next";
import { svgs } from "../../../../core/constants/svgs";

const CategoryBasedTypes = ({ data, img, onLookupClick, isVisible = true }) => {
  const { t } = useTranslation();
  const key = "category_based_types";

  return (
    <div style={isVisible ? {height: 'auto', visibility: 'visible'} : {height: '0px', visibility: 'hidden'}}>
      {data?.[key]?.length > 0 && (
        <div id={key + "-title-id"}>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6 mb-3">
              <h4 className="section-desc">
                {t("how_do_you_want_to_organize_your_chapters")}?
              </h4>
            </div>
            <div className="col-lg-8 col-md-6" />
          </div>
          <div className="row justify-content-center">
            {data?.[key]?.map((item, index) => (
              <FeatureCard
                key={key + "-" + index}
                onClick={onLookupClick(key, item)}
                img={ index === 0
                  ? svgs.idea
                  : svgs.clockTwoThirty}
                name={item?.name}
                description={item?.description}
                is_selected={item?.is_selected}
              />
            ))}
          </div>
        </div>
      )}
    </ div>
  );
};

export default CategoryBasedTypes;
