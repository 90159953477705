import React, { createContext, useState } from "react";
import { getCookie } from "../common-service/CommonService";

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const isToken = getCookie("token") && getCookie.length > 0;
  const [isLoggedIn, setIsLoggedIn] = useState(isToken);
  const [user, setUser] = useState({});
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <StoreContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        mobileMenu,
        setMobileMenu,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
