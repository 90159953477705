import React from "react";
import "./Tooltip.css";

const Tooltip = ({ children, text }) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-trigger d-flex justify-content-center">
        {children}
      </div>
      <div className="tooltip-content">{text}</div>
    </div>
  );
};

export default Tooltip;
