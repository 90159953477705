import React from "react";
import img from "../../../assets/img/others/error-vect.svg";
import bg from "../../../assets/img/bg/bread-crumb-bg.jpeg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const goToPage = (path) => (e) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: `url(${bg}) no-repeat center center/cover`,
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title">Error</h2>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                    <a
                      onClick={goToPage("/")}
                      href="/"
                      className="aai-breadcrumb-link text-decoration-underline"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                  <li>
                    <a
                      onClick={goToPage("/")}
                      href="/"
                      className="aai-breadcrumb-link"
                    >
                      {" "}
                      {"Error"}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aai-error pt-100 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="text-center">
                <img src={img} className="img-fluid" alt="" />
              </div>
              <div className="text-center mt-5">
                <h2 className="section-title">Oops! Page not found.</h2>
                <div className="mt-5 d-flex flex-column justify-content-center flex-md-row aai-btns-group">
                  <a
                    onClick={goToPage("/")}
                    href="/"
                    className="aai-btn btn-pill-solid"
                  >
                    Back To Home
                  </a>
                  <a
                    onClick={goToPage("/")}
                    href="/"
                    className="aai-btn aai-gradient-outline-btn"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
