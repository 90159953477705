import React, { useState } from "react";
import { postForgotPassword } from "../../core/api/Api";
import img from "../../assets/img/others/ai-ills.svg";
import bg from "../../assets/img/bg/sign-in-up.jpeg";
import { useTranslation } from "react-i18next";
import { showToast } from "../../core/common-service/CommonService";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onChangeEmail = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    postForgotPassword({ email })
      .then((res) => {
        setLoading(false);
        const status_code = res?.status;
        if (status_code === 200) {
          const response = res?.data;
          showToast({ type: "success", message: response?.success });
        } else {
          showToast({ type: "error", message: t("error.wrong_email") });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <section
      className="aai-signup-in"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
            <div className="position-relative">
              {/* <img src={img} className="img-fluid aai-signup-in-img" alt="" /> */}
            </div>
          </div>
          <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
            <div className="aai-form-wrapper">
              <div className="">
                <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center mb-3">
                  <h2 className="aai-form-title">{t("reset_your_password")}</h2>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-12">
                  <div className="aai-form-container">
                    <input
                      className="form-control shadow-none"
                      id="email"
                      name="email"
                      type="email"
                      placeholder={t("your_email_address")}
                      value={email}
                      onChange={onChangeEmail}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-grid">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      disabled={loading}
                      className="aai-btn btn-pill-solid"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
