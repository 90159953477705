export const svgs = {
  autobiography: require("../../assets/svg/autobiography.svg").default,
  writeAStory: require("../../assets/svg/write_a_story.svg").default,
  gripHorizontalTwoLines:
    require("../../assets/svg/grip_horizontal_two_lines.svg").default,
  gripHorizontalThreeLines:
    require("../../assets/svg/grip_horizontal_three_lines.svg").default,
  gripForizontalFourLines:
    require("../../assets/svg/grip_horizontal_four_lines.svg").default,
  clockTwoThirty: require("../../assets/svg/clock_two_thirty.svg").default,
  idea: require("../../assets/svg/idea.svg").default,
  draggable: require("../../assets/svg/draggable.svg").default,
  mastercard: require("../../assets/svg/mastercard.svg").default,
  visa: require("../../assets/svg/visa.svg").default,
  upload: require("../../assets/svg/upload.svg").default,
};
