import React, { useState, useEffect } from 'react';

const ShowAfterDelay = ({ children, delay }) => {
  const [showTag, setShowTag] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTag(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div>
      {children(showTag)}
    </div>
  );
};

export default ShowAfterDelay