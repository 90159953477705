import { toast } from "react-toastify";

export function setCookie(cname, cvalue) {
  const exdays = 30;
  var secure = "";
  if (window.location.protocol == "https") {
    secure = "secure;";
  }
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + secure + expires + "; path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function deleteCookie(cname) {
  document.cookie =
    cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}

export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  return browserLanguage?.split("-")[0];
};

export const updateElementClassById = (id, className, action) => {
  const el = document.getElementById(id);
  if (el) {
    el.classList[action](className);
  }
};

export function removeWhiteSpaceFromString(text) {
  return text?.replace(/\s/g, "");
}

export const emailValidation = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const checkFormErrors = (formErrors) => {
  return Object.values(formErrors).every((item) => item == true);
};

export function getInputSpeechId(q) {
  return "question_input_" + q?.id + "_" + q?.name;
}

export const countValidTranscripts = (data) => {
  let count = 0;
  data.forEach((category) => {
    category.questions.forEach((question) => {
      if (question.transcript && question.transcript.trim() !== "") {
        count++;
      }
    });
  });
  return count;
};

export const countTotalQuestions = (data) => {
  let total = 0;
  data.forEach((category) => {
    total += category.questions.length;
  });
  return total;
};

export const checkIfChapterHaveLessThanOneQuestion = (data) => {
  data.forEach((element) => {
    if (element?.questions?.length === 0) {
      return true;
    }
  });
  return false;
};

export const generateRandomString = (length = 8) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const showHideModal = (id) => {
  const modal = document.getElementById(id);
  if (modal?.style?.display === "none") {
    modal.style.display = "block";
  } else {
    modal.style.display = "none";
  }
};

export const showToast = ({
  type,
  message,
  position = "bottom-left",
  autoClose = 4000,
}) => {
  toast[type](message, {
    position,
    autoClose,
  });
};

export const getDateInTimeZone = (
  date = new Date(),
  timeZone = "Europe/London"
) => {
  // Format the date with the specified timezone
  let options = {
    timeZone: timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  // Create a formatter
  let formatter = new Intl.DateTimeFormat("en-US", options);
  let parts = formatter.formatToParts(date);

  // Assemble the formatted date
  let formattedDate = `${parts.find((part) => part.type === "year").value}-${
    parts.find((part) => part.type === "month").value
  }-${parts.find((part) => part.type === "day").value} ${
    parts.find((part) => part.type === "hour").value
  }:${parts.find((part) => part.type === "minute").value}:${
    parts.find((part) => part.type === "second").value
  }`;

  return formattedDate;
};

async function deriveKey() {
  const enc = new TextEncoder();
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    enc.encode(process.env.REACT_AES_KEY),
    "PBKDF2",
    false,
    ["deriveBits", "deriveKey"]
  );

  return await window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: enc.encode("salt"),
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );
}

export async function encryptString(string) {
  const key = await deriveKey();
  const encoded = new TextEncoder().encode(string);
  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encoded
  );
  return {
    iv: Array.from(iv),
    encrypted: Array.from(new Uint8Array(encrypted)),
  };
}

export async function decryptString(encryptedData) {
  try {
    const key = await deriveKey();
    const iv = new Uint8Array(encryptedData.iv);
    const encrypted = new Uint8Array(encryptedData.encrypted);
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encrypted.buffer
    );
    return new TextDecoder().decode(decrypted);
  } catch (error) {
    console.log("Decrypt Error", error);
    return "{}";
  }
}
