import React, { useRef, useState, useEffect } from "react";
import "./Payment.css";
import bg from "../../assets/img/bg/aai-feature-bg.jpeg";
import KRGlue from "@lyracom/embedded-form-glue";
import {
  postCreatePaymentForm,
  getOnPaymentCallback,
  PAYMENT_GATEWAY_PUBLIC_KEY,
  getTotalUsersCount,
} from "../../core/api/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showToast } from "../../core/common-service/CommonService";

const Payment = () => {
  const mounted = useRef(true);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  // let { service_session_id, plan_id } = useParams();
  const {
    service_session_id,
    plan_id,
    is_add_chapter,
    chapter_to_add,
    index_chapter_to_add,
  } = location.state || {};

  const [nbOfUsers, setNbOfUsers] = useState()

  // useEffect(() => {

  //   const _totalNbUsers = async () => {
  //     const {data} = await getTotalUsersCount()
  //     const {success: {total_users_count}} = data

  //     setNbOfUsers(total_users_count)
  //   }

  //   _totalNbUsers()
  // }, [nbOfUsers])

  useEffect(() => {
    window.scroll(0, 0)
    async function setupPaymentForm() {
      const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
      const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;

      try {
        postCreatePaymentForm({
          service_session_id,
          plan_id,
          is_add_chapter,
        })
          .then(async (res) => {
            const response = res?.data?.success;
            if (mounted.current && response) {
              const formToken = response?.formToken;

              const { KR } = await KRGlue.loadLibrary(
                endpoint,
                publicKey
              ); /* Load the remote library */

              await KR.setFormConfig({
                /* set the minimal configuration */
                formToken,
                "kr-language":
                  i18n.language /* to update initialization parameter */,
              });

              await KR.renderElements(
                "#myPaymentForm"
              ); /* Render the payment form into myPaymentForm div */

              await KR.onSubmit(async (res) => {
                const orderStatus = res.clientAnswer.orderStatus;
                if (orderStatus === "PAID") {
                  setPaymentStatus(orderStatus);
                  getOnPaymentCallback(service_session_id)
                    .then((res) => {
                      const response = res?.data?.success;
                      if (mounted.current && response) {
                        showToast({ type: "success", message: response });
                        const obj = {
                          chapter_to_add,
                          index_chapter_to_add,
                        };
                        navigate("/questions/" + service_session_id, {
                          state: { ...obj },
                        });
                      }
                    })
                    .catch((e) => {});
                }
                return false; // Return false to prevent the redirection
              });
            }
          })
          .catch((e) => {});
      } catch (error) {
        navigate(-1);
        setMessage(error + " (see console for more details)");
      }
    }
    setupPaymentForm();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <section
      className="aai-features py-100 payment"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      {paymentStatus === "PAID" && (
        <div className="container pb-100">
          <div className="row">
            <div className="col-12">
              <h2 className="paid-message-header">
                Nous vous confirmons votre achat.
              </h2>
              <p className="paid-message-description">
                Profitez désormais de toutes les fonctionnalités de TaLegacy
                pour l'écriture de votre histoire!
              </p>
            </div>
          </div>
        </div>
      )}
      {/* {nbOfUsers && <h2 className="text-center font-semibold" style={{marginBottom: '1rem', fontSize: '1.3rem'}}>
        {nbOfUsers} {t('people_have_already_written_their_stories_with_taLegacy')}
      </h2>} */}
      <div className="form">
        <div className="d-flex align-items-center justify-content-center">
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true"></div>
          </div>
          <div data-test="payment-message">{message}</div>
        </div>
      </div>
    </section>
  );
};

export default Payment;
