import React, { useEffect, useState, useMemo } from "react";
import "./Question.css";
import {
  getInputSpeechId,
  generateRandomString,
} from "../../../../../../core/common-service/CommonService";
import { postGetGoogleSpeechToText } from "../../../../../../core/api/Api";
import { MAX_AUDIO_LENGTH } from "../../../../../../core/constants/constants";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../../_components/tooltip/Tooltip";
import formatNumber from "../../../../../../core/utils/formatNumber";
import useIsMobile from "../../../../../../core/hooks/useMobile";
import { svgs } from "../../../../../../core/constants/svgs";

const Question = ({
  question,
  onresult,
  onEditDeleteQuestion,
  showSideOption = true,
  onDragStart,
  onDragEnter,
  onDragEnd,
  draggable,
}) => {
  const [recordLoading, setRecordLoading] = useState(false);
  const { t } = useTranslation();
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();

  const [textLength, setTextLength] = useState(0);

  function padValue(value) {
    return value < 10 ? `0${value}` : value;
  }

  const setTimerHtml = (audioRecorderTimer) => {
    const remainingSeconds = MAX_AUDIO_LENGTH - audioRecorderTimer;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    return `${padValue(minutes)}:${padValue(seconds)}`;
  };

  const setTimeStyle = useMemo(() => {
    return {
      color: MAX_AUDIO_LENGTH - recordingTime < 30 ? "red" : "",
    };
  }, [recordingTime]);

  const onChangeTextArea = ({ target: { value } }) => {
    setTextLength(value.length);
    document.getElementById(getInputSpeechId(question)).value = value;
    onresult(value);
  };

  const onBlurTextArea = ({ target: { value } }) => {
    const val = value?.trimEnd();
    document.getElementById(getInputSpeechId(question)).value = val;
    onresult(val);
  };

  useEffect(() => {
    if (recordingTime > MAX_AUDIO_LENGTH) {
      stopRecording();
    }
    return () => {};
  }, [recordingTime]);

  useEffect(() => {
    if (!recordingBlob) return;

    const formData = new FormData();
    const audio_name =
      "audio_" + generateRandomString() + "_" + Date.now() + ".webm";
    formData.append("audio", recordingBlob, audio_name);
    setRecordLoading(true);
    postGetGoogleSpeechToText(formData)
      .then((res) => {
        setRecordLoading(false);
        var transcript =
          document.getElementById(getInputSpeechId(question)).value ?? "";
        try {
          const googleTranscript = res?.data?.success?.transcript;
          if (googleTranscript) {
            transcript =
              (transcript?.length > 0 ? ", " : "") + googleTranscript;
            var new_val = (
              document.getElementById(getInputSpeechId(question)).value +
              transcript
            )?.trim();
            document.getElementById(getInputSpeechId(question)).value = new_val;
            onresult(new_val);
          }
        } catch (error) {
          setRecordLoading(false);
        }
      })
      .catch((e) => {
        setRecordLoading(false);
      });
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob]);

  const isMobile = useIsMobile();

  return (
    <div className={`${isMobile ? "mt-2" : "mt-4"}`}>
      <div
        className="aai-comment-input mb-3 cursor-move"
        onDragStart={onDragStart}
        onDragEnter={onDragEnter}
        onDragEnd={onDragEnd}
        draggable={draggable}
      >
        <label className="aai-comment-form-label label-flex">
          <div className="left">
            {!isMobile && <img src={svgs.draggable} width={24} />}
            <span className="question">{question?.name}</span>
          </div>
          {!isMobile && (
            <div className="words-count" style={{ marginRight: "1rem" }}>
              <Tooltip text={t("number_of_recommended_characters")}>
                <span>
                  {formatNumber(textLength)} {t("character")}
                  {textLength > 1 ? "s" : ""}
                </span>
              </Tooltip>
            </div>
          )}
          {recordingTime > 0 && setTimerHtml(recordingTime)}
        </label>
        <div className="d-flex align-items-start">
          <div className="d-flex flex-column justify-content-center me-2">
            <Tooltip text={t("speed_to_text")}>
              <button
                className="question_record_button btn"
                onClick={isRecording ? stopRecording : startRecording}
              >
                <i
                  className={
                    "fa-solid " +
                    (recordLoading
                      ? "fa-spinner fa-spin"
                      : isRecording
                      ? "fa-beat-fade fa-record-vinyl text-danger"
                      : "fa-microphone")
                  }
                ></i>{" "}
              </button>
            </Tooltip>
            {showSideOption && (
              <>
                <Tooltip text={t("edit_question")}>
                  {/* Edit */}
                  <button
                    className="aai-breadcrumb-link text-decoration-underline mt-2"
                    onClick={onEditDeleteQuestion("edit")}
                  >
                    <i className="fa-solid fa-pen-to-square icons"></i>
                  </button>
                  {/* Delete */}
                </Tooltip>
                <Tooltip text={t("delete_question")}>
                  {/* Edit */}
                  <button
                    className="aai-breadcrumb-link text-decoration-underline mt-2"
                    onClick={onEditDeleteQuestion("delete")}
                  >
                    <i className="fa-solid fa-trash icons" />
                  </button>
                  {/* Delete */}
                </Tooltip>
                <Tooltip text={t("magic_rephrase")}>
                  <button
                    className="aai-breadcrumb-link text-decoration-underline mt-2"
                    onClick={onEditDeleteQuestion("optimize")}
                  >
                    <i
                      id={getInputSpeechId(question) + "_optimize_icon"}
                      className="fa-solid fa-wand-magic-sparkles icons"
                    />
                  </button>
                </Tooltip>
              </>
            )}
          </div>
          <textarea
            className="form-control shadow-none text-area"
            id={getInputSpeechId(question)}
            disabled={recordLoading}
            onChange={onChangeTextArea}
            onBlur={onBlurTextArea}
            value={question?.transcript ?? ""}
            maxLength={5000}
            onSelect={(e) => {
              const selectedText = e.target.value.substring(
                e.target.selectionStart,
                e.target.selectionEnd
              );
              console.log("selectedText", selectedText);
            }}
          />
        </div>
        {isMobile && (
          <div
            className="words-count"
            style={{ textAlign: "right", marginTop: "3px" }}
          >
            <span>
              {formatNumber(textLength)} {t("character")}
              {textLength > 1 ? "s" : ""}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Question;
