import React, { useEffect } from "react";
import { Modal, Button } from "antd";
import "./index.css";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../core/hooks/useMobile";
import { svgs } from "../../../core/constants/svgs";

const LockedBookModal = ({
  open,
  setOpen,
  servicesWithPlans,
  isPayed,
  code,
  onCodeChange,
  checkCouponForPayment,
  onChoosePlanClick,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("servicesWithPlans", servicesWithPlans);
  }, [servicesWithPlans]);

  return (
    <Modal
      destroyOnClose
      title={
        <>
          <div className="modal-title">
            <img
              src="/logo192.png"
              alt="logo"
              width={32}
              className="modal-logo"
            />
            <h6>TaLegacy</h6>
          </div>
        </>
      }
      footer={null}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={isMobile ? 1000 : 700}
      classNames={{ mask: "mask" }}
    >
      <div className="modal-container">
        <div className="modal-header">
          <h1>{t("finish_your_book")}</h1>
        </div>
        {!isPayed && (
          <div className="coupon-input-container">
            <input
              className="form-control shadow-none"
              id="coupon"
              name="coupon"
              type="text"
              placeholder={t("enter_your_coupon")}
              value={code}
              onChange={onCodeChange}
            />
            <button
              onClick={checkCouponForPayment}
              type="submit"
              className="aai-btn btn-pill-solid d-flex justify-content-center"
            >
              <i className="fa-solid fa-check"></i>
            </button>
          </div>
        )}
        <div className="modal-divider" />
        <label className="modal-offer-label">
          <div className="modal-offer-content">
            <div className="modal-offer-input-container">
              <input
                name="offer"
                checked={true}
                className="modal-offer-input"
                type="radio"
              />
            </div>
            <div className="offer-details-container">
              <div className="modal-offer-details">{`${servicesWithPlans[0].plans[0].price}€ TTC`}</div>
              <div className="modal-offer-subtext">{t('single_payment')}</div>
            </div>
          </div>
          {servicesWithPlans[0].plans[0].plan_options && (
            <div>
              <ul className="plan-options-list">
                {servicesWithPlans[0].plans[0].plan_options.filter(item => item.is_active).map((item) => (
                  <li key={item.name}><i className='fa-regular fa-circle-check' /> {item.name}</li>
                ))}
              </ul>
            </div>
          )}
        </label>
        <div className="modal-divider" />
        <div className="modal-action">
          <div className="secure-payment">
            <i className="fa-solid fa-lock lock-icon"></i>
            <div className="secure-payment-text">{t("secure_credit_card_payment")}</div>
          </div>
          <Button
            type="primary"
            className="modal-button"
            onClick={onChoosePlanClick}
          >
            {t("continue")}
          </Button>
          <div className="modal-payment-icons">
            <img src={svgs.mastercard} alt="mastercard" width={32} />
            <img src={svgs.visa} alt="visa" width={32} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LockedBookModal;
