import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/style.css";
import "./assets/css/font-awesome-all.min.css";
import "swiper";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-circular-progressbar/dist/styles.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import StoreContext from "./core/context/StoreContext";
import "./i18n";

AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <StoreContext>
    <App />
  </StoreContext>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
